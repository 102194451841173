import * as React from "react"
import Layout from "./src/components/layout"
import "./src/index.css"

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  )
}
