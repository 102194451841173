exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aup-index-js": () => import("./../../../src/pages/aup/index.js" /* webpackChunkName: "component---src-pages-aup-index-js" */),
  "component---src-pages-ccpa-index-js": () => import("./../../../src/pages/ccpa/index.js" /* webpackChunkName: "component---src-pages-ccpa-index-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-dataprocessing-index-js": () => import("./../../../src/pages/dataprocessing/index.js" /* webpackChunkName: "component---src-pages-dataprocessing-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-snapshots-index-js": () => import("./../../../src/pages/snapshots/index.js" /* webpackChunkName: "component---src-pages-snapshots-index-js" */),
  "component---src-pages-tos-index-js": () => import("./../../../src/pages/tos/index.js" /* webpackChunkName: "component---src-pages-tos-index-js" */),
  "component---src-templates-archive-page-js": () => import("./../../../src/templates/archive-page.js" /* webpackChunkName: "component---src-templates-archive-page-js" */)
}

