/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
//import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          className="site-footer"
          style={{
            marginTop: `2rem`,
          }}
        >

          <div className="wrapper">

            <h2 className="footer-heading">Fastly Docs Archive</h2>

            <div className="footer-col-wrapper">
              <div className="footer-col  footer-col-1">
                <ul className="contact-list">
                  <li key='www'><a href="https://www.fastly.com/">www.fastly.com</a></li>
                  <li key='docs'><a href="https://docs.fastly.com/">docs.fastly.com</a></li>
                </ul>
              </div>

              <div className="footer-col  footer-col-2">
                <ul className="social-media-list">
                  <li>
                    <a href="https://twitter.com/fastly">
                      <span className="icon  icon--twitter">
                        <svg viewBox="0 0 16 16">
                          <path fill="#828282" d="M15.969,3.058c-0.586,0.26-1.217,0.436-1.878,0.515c0.675-0.405,1.194-1.045,1.438-1.809
                          c-0.632,0.375-1.332,0.647-2.076,0.793c-0.596-0.636-1.446-1.033-2.387-1.033c-1.806,0-3.27,1.464-3.27,3.27 c0,0.256,0.029,0.506,0.085,0.745C5.163,5.404,2.753,4.102,1.14,2.124C0.859,2.607,0.698,3.168,0.698,3.767 c0,1.134,0.577,2.135,1.455,2.722C1.616,6.472,1.112,6.325,0.671,6.08c0,0.014,0,0.027,0,0.041c0,1.584,1.127,2.906,2.623,3.206 C3.02,9.402,2.731,9.442,2.433,9.442c-0.211,0-0.416-0.021-0.615-0.059c0.416,1.299,1.624,2.245,3.055,2.271 c-1.119,0.877-2.529,1.4-4.061,1.4c-0.264,0-0.524-0.015-0.78-0.046c1.447,0.928,3.166,1.469,5.013,1.469 c6.015,0,9.304-4.983,9.304-9.304c0-0.142-0.003-0.283-0.009-0.423C14.976,4.29,15.531,3.714,15.969,3.058z"/>
                        </svg>
                      </span>

                      <span className="username">fastly</span>
                    </a>
                  </li>

                </ul>
              </div>

              <div className="footer-col  footer-col-3">
                <p className="text">Archive for docs.fastly.com and www.fastly.com<br/>
                © {new Date().getFullYear()} Fastly</p>
              </div>
            </div>

          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
