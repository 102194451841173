import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
      borderTop: `5px solid #C20105`,
      borderBottom: `1px solid #e8e8e8`,
      minHeight: `56px`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `#424242`,
            fontSize: `26px`,
            lineHeight: `56px`,
            letterSpacing: `-1px`,
            marginBottom: `0`,
            textDecoration: `none`,
            whiteSpace: `nowrap`
          }}
        >
        <img src="/assets/images/fastly-logo.png" height="45" alt="fastly logo"/>&nbsp;|&nbsp;DOCS ARCHIVE
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
